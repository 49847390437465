import React, { useState, useRef, useEffect } from "react"
import Layout, { Container } from "../components/layout"
import SEO from "../components/SEO"
import { SVGMap } from "react-svg-map"
import Stift from "../components/stiftsMap"
import Slanted, { NormalHeading } from "../components/layout/slanted"
import { graphql, Link } from "gatsby"

function getLocationName(event) {
  return event.target.attributes.name.value
}

const Listor = ({
  data: {
    allWpLista: { edges },
  },
}) => {
  const [showList, setShowList] = useState(false)
  const [state, setState] = useState({
    pointedLocation: null,
    focusedLocation: null,
    selectedLocation: null,
    position: { top: 0, left: 0 },
    listName: null,
    content: {},
  })

  function handleLocationMouseOver(event) {
    const pointedLocation = getLocationName(event)

    setState({
      ...state,
      pointedLocation: pointedLocation,
    })
  }

  function handleLocationMouseMove(event) {
    setState({
      ...state,
      position: {
        top: event.clientY + window.pageYOffset,
        left: event.clientX + 20,
      },
    })
  }

  function handleLocationMouseOut() {
    setState({ ...state, pointedLocation: null })
  }

  function handleLocationFocus(event) {
    const focusedLocation = getLocationName(event)
    if (!showList) {
      setState({
        ...state,
        focusedLocation: focusedLocation,
        listName: focusedLocation,
      })
      setShowList(true)
    }
  }

  function handleLocationBlur() {
    if (!showList) {
      setState({ ...state, focusedLocation: null })
    }
  }

  function handleOnChange(selectedNode) {
    setState({
      ...state,
      selectedLocation: selectedNode.attributes.name.value,
    })
  }

  const wrapperRef = useRef(null)

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowList(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [wrapperRef])

  useEffect(() => {
    const content = edges.find(({ node }) => node.title === state.listName)

    setState({ ...state, content })
  }, [state.listName])

  const { node, next, previous } = state.content
    ? state.content
    : { node: null, next: null, previous: null }

  return (
    <>
      <Layout>
        <SEO
          title="Våra kandidater i kyrkovalet"
          description="Se vilka som kandidarerar för oss i ditt stift i valet till kyrkomötet!"
        />
        <Slanted innerClass="bg-white">
          <NormalHeading>Kandidater</NormalHeading>
          <p>Klicka på ditt stift för att se den lokala valsedeln! </p>
          <p>
            Du kan beställa valsedlar gratis <Link to="/">här!</Link>
          </p>
          <SVGMap
            map={Stift}
            className="map-wrapper"
            locationClassName="map"
            onLocationMouseOver={handleLocationMouseOver}
            onLocationMouseOut={handleLocationMouseOut}
            onLocationFocus={handleLocationFocus}
            onLocationBlur={handleLocationBlur}
            onLocationMouseMove={handleLocationMouseMove}
            onChange={handleOnChange}
          />
          <div
            className="hidden md:block absolute "
            style={{ top: state.position.top, left: state.position.left }}
          >
            {state.pointedLocation && (
              <span className="bg-white rounded bg-opacity-50 p-2">
                {state.pointedLocation}
              </span>
            )}
          </div>
        </Slanted>
      </Layout>
      {showList && (
        <div className="modal h-screen w-full fixed left-0 top-0 flex flex-col justify-center items-center bg-white bg-opacity-50 z-10">
          <div
            ref={wrapperRef}
            className="bg-yellow-100 shadow-lg w-11/12   md:max-w-lg relative flex flex-col justify-center items-center"
          >
            <div className=" px-4 py-2 flex flex-col justify-between items-center">
              <button
                className="absolute top-4 right-4 text-2xl"
                onClick={() => setShowList(false)}
              >
                &#10006;
              </button>
              <p>Val till kyrkomötet</p>
              <h3 className="my-0">
                {state.listName ? state.listName : state.focusedLocation}
              </h3>
            </div>
            <div
              className="p-2 md:p-6 text-sm md:text-base w-full md:w-max md:pr-12 pb-4 valsedel"
              dangerouslySetInnerHTML={{
                __html: node ? node.content : null,
              }}
            ></div>
            <div className="flex flex-row justify-between w-full p-4">
              <div>
                {previous && (
                  <button
                    onClick={() =>
                      setState({ ...state, listName: previous.title })
                    }
                  >
                    Förra
                  </button>
                )}
              </div>
              <div>
                {next && (
                  <button
                    onClick={() => setState({ ...state, listName: next.title })}
                  >
                    Nästa
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Listor

export const query = graphql`
  query listQuery {
    allWpLista(sort: { fields: title, order: ASC }) {
      edges {
        node {
          title
          content
        }
        next {
          title
        }
        previous {
          title
        }
      }
    }
  }
`
